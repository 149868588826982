@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-utilities.min.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

:root {
    --hi-primary: #285A7B;
    --hi-light: #ffffff;

}

.btn-hi-primary {
    background-color: var(--hi-primary) !important;
    color: var(--hi-light) !important;
}
.text-des{
  color: var(--hi-primary);
  font-size: 20px;
}
.text-hi {
  color: var(--hi-primary);
  font-weight: bold;
  font-size: 40px;
}

.text-hi-text {
  color: var(--hi-primary);
}
.project-section {
  margin: 5em 0;
}
.enabling-text {
  font-weight: bold;
  font-size: 50px;
  line-height: 140%;
  letter-spacing: 0.6px;
  color: var(--hi-primary);
}
.connecting-text {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.03em;
  color: var(--hi-primary);
}
/* .leftText span {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  width: 428px;
  color: #285a7b;
} */
.leftText {
  margin-top: 10em;
}
.leftText p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: var(--hi-primary);
  width: 438px;
}

.leftText button {
  background: var(--hi-primary);
  border-radius: 4px;
  width: 190px;
  border: none;
  color: white;
}
.hero {
  width: 100%;
  margin: auto;
}
/* .leftText {
  margin: 23%;
} */
.herorow {
  background: linear-gradient(
    180deg,
    #e5efff 0%,
    rgba(229, 239, 255, 0.262661) 80.34%,
    rgba(229, 239, 255, 0) 100%
  );
}

.colon{
  width: 46px;
height: 84px;
left: 42px;
top: 45px;
font-family: DM Serif Display;
font-style: normal;
font-weight: normal;
font-size: 108px;
line-height: 84px;
display: flex;
align-items: center;
text-align: center;
color:  #285A7B;
}
.card_bg{
  background-color: #E7F0FF;
  position: relative;
  height: 500px;
  margin-top: 10em !important;
}
.card_style{
  position: absolute;
  top: -7em !important;
  left: 50%;
  transform: translateX(-50%);
}
.company_name{
  font-weight: bold;
font-size: 26px;
color:  #285A7B;;
}
.company_prof{
  position: absolute;
  width: 100% !important;
  bottom: -380px !important;
  left: 50%;
  transform: translateX(-50%);
}
.card_radius{
  border-radius: 25px;
  /* box-shadow: 5px 20px 50px 0px #4A99D333; */
  border: none !important;

}
.str{
  align-items: baseline !important;
}
.card_arrow{
  width: 50px;
  float: right;
  padding-top: 2em;
}
.market{
  margin-top: 20em !important;
  background: #E7F0FF;
}
.market_info{
  font-size: 20px;
  color: #285A7B;
}
.hr{
  border: 1px solid #285A7B;
  height: 2px;
  background-color:#285A7B;
}
.worldmap{
  height: 711px;
  width:100%;
  border-radius: 23px;
  margin: auto !important;
  
}
.times{
  margin: 0%;
  padding: 0%;
}
.timeline{
  position: relative;
  margin: 50px auto;
  padding: 40px 0;
  width: 1000px;

}
.timeline::before{
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  height: 90%;
  border-right: 2px dashed #285A7B;;
}
.timeline ul{
  margin: 0;
  padding: 0%;

}
.timeline ul li{
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;

}
.timeline ul li:nth-child(odd){
  float: left;
  text-align: right;
  clear: both;
}
.timeline ul li:nth-child(even){
  float: right;
  text-align: left;
  clear: both;
}
.content{
  padding-bottom: 20px;
}
.timeline ul li:nth-child(odd)::before{
  content: "";
  position: absolute;
  top: 0px;
  right: -6px;
  width: 10px;
  height: 10px;
  background:#285A7B;
  border-radius: 50%;
} 
.timeline ul li:nth-child(even)::before{
  content: "";
  position: absolute;
  top: 0px;
  left: -4px;
  width: 10px;
  height: 10px;
  background:#285A7B;
  border-radius: 50%;
} 
.timeline ul li h3{
  margin: -30px 0 0 0%;
  padding: 0 0 30px 0 !important;
}
/* .timeline ul li p{
  margin: 10px 0 0;
  padding: 0;
} */
.timeline ul li:nth-child(odd) .time{
position: absolute;
top: -8px;
right: -325px;
margin: 0%;
padding: 0px 16px;
}
.timeline ul li:nth-child(even) .time{
  position: absolute;
  top: -8px;
  left: -325px;
  margin: 0%;
  padding: 0px 16px;
  }
  .continue{
    margin-top: -2em !important;
    color: #B4BEC8;
    font-weight: bold;
    font-size: 26px;
  }
  .actives{
    /* background: burlywood; */
    background-color: #E7F0FF;
    font-weight: bolder;
  }

  .footer {
    background-color: #285a7b;
  }
  .quick-links {
    list-style: none;
  }
  .hr {
    border: 2px solid white;
    height: 2px;
    background-color: white;
  }

  .custom-modal {
    z-index: 10000;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .closeBtn{
    margin: 30px 30px 0 auto !important;

  }
  .map{
    border-radius: 5%;
  }
  .main_modal{
    border-radius: 5% !important;
  }
  .contact_modal{
    border-radius: 25px !important;
  }
  .location{
    margin-top: -2.5em !important;
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.world_map{
  margin-top: 22em !important;
}
input[type=number] {
  -moz-appearance: textfield;
}

/* Learn more css */

.bg-blue-linear{
  background: linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 80.34%, rgba(229, 239, 255, 0) 100%);
}
.link{
  text-decoration: none;
}
.main-para{
  font-size: 24px;
  color: #285A7B;
}
.sub-titles{
  font-weight: bold;
  color: #285A7B;
  font-size: 20px;
}
.headings{
  font-weight: bold;
  color: #285A7B;
  font-size: 18px;
}
.address{
  font-size: 17px;
  color: #285A7B;
}
.tech-images{
  width: 272.23px;
height: 215.13px;
}
.tech-cards{
  border: transparent;
  background: transparent;
  /* width: 407px;
  height: 440px; */
  transition: 300ms;

}
.tech-cards:hover{
  border: 2px solid skyblue;
  background: #FFFFFF;
  box-shadow: 0px 2px 46px rgba(74, 153, 211, 0.8);
  border-radius: 50px;
  
}
/* Services CSS */
.services-section {
  margin: 6em 0;
}
.services-title {
  font-weight: bold;
  font-size: 35px;
  line-height: 60px;
  color: var(--hi-primary);
  position: relative;
}
.services-img {
  width: 50%;
  height: 250px;
}
.services-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 48%;
  background: var(--hi-primary);
  width: 4%;
  height: 5px;
}
.services-sub-title {
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.03em;
  color: var(--hi-primary);
}
.services-des {
  font-size: 20px;
  line-height: 30px;
  color: var(--hi-primary);
}

.owl-nav {
  display: none !important;
}

.hero {
  background: linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 80.34%, rgba(229, 239, 255, 0) 100%);
}
/* Connectivity CSS */
.connect-des {
  line-height: 30px;
  letter-spacing: 0.03em;
}

.open {
  line-height: 20px !important;
/* webTechnology css */
}
.web-title{
  color: #285A7B;
  
}
.frontend-development{
  color: #285A7B;
}
.list-items{
  color: #285A7B;
}
.connectivity {
  padding: 7em 0;
}